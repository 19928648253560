<template>

  <div class="card form-card checkout">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5>{{ $t('paymentMethodTitle') }}</h5>

      <button v-if="isPaymentFormUI && !paymentMethodExists" class="btn btn-success" @click.prevent="addPaddlePaymentMethod">
        <i class="feather icon-plus mx-1"></i>{{ $t('paymentMethodTab.addButton') }}
      </button>

      <button v-if="isPaymentFormUI && paymentMethodExists" class="btn btn-sm btn-light" @click.prevent="updatePaddlePaymentMethod">
        <i class="feather icon-edit mx-1"></i>{{ $t('paymentMethodTab.editButton') }}
      </button>

      <button v-if="!isPaymentFormUI && !paddleIsLoading && paddleIsOnWork" class="btn btn-sm btn-light" @click.prevent="cancelPaddleForm">
        <i class="feather mx-1"></i>{{ $t('paymentMethodTab.cancelButton') }}
      </button>

    </div>

    <div class="sender_name collapse show">
      <div class="card-block task-details">

      <div class="mt-n4">
        <alert type="info" text="warnings.no-store-card" class="mt-2 mb-2" />
      </div>

        <spinner-component v-if="isPaymentDataLoading" item-class="table-spinner-layout"></spinner-component>
        <spinner-component v-if="paddleIsLoading" item-class="table-spinner-layout"></spinner-component>

        <div v-show="!isPaymentFormUI && !paddleIsLoading">
          <div :class="paddleIsLoading ? 'checkout-container d-none' : 'checkout-container d-flex justify-content-center'"></div>
        </div>

        <div v-if="isPaymentFormUI && paymentMethodExists" class="row mb-0">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table invoice-detail-table">
                <tbody>
                  <tr>
                    <td>
                      <h6>{{ $t('paymentMethodTab.paymentType') }}:</h6>
                      <p class="m-0">{{ $t(`paymentMethodTab.paymentTypes.${paymentData.payment.payment_method}`) }}</p>
                    </td>
                  </tr>
                  <tr v-if="paymentData.payment.payment_method == 'card'">
                    <td>
                      <h6>{{ $t('paymentMethodTab.cardType') }}:</h6>
                      <p class="m-0">{{ $t(`paymentMethodTab.cardTypes.${paymentData.payment.card_type}`) }}</p>
                    </td>
                  </tr>
                  <tr v-if="paymentData.payment.last_four_digits">
                    <td>
                      <h6>{{ $t('paymentMethodTab.cardNumber') }}:</h6>
                      <p class="m-0">xxxx-xxxx-xxxx-{{ paymentData.payment.last_four_digits }}</p>
                    </td>
                  </tr>
                  <tr v-if="paymentData.payment.expiry_date">
                    <td>
                      <h6>{{ $t('paymentMethodTab.expiry') }}:</h6>
                      <p class="m-0">{{ paymentData.payment.expiry_date }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div
          v-if="isPaymentFormUI && paymentMethodExists"
          class="row juatify-content-start"
        >
          <div class="col col-12">
            <div class="d-flex flex-row justify-content-end">
              <modal-wrapper>
                <template v-slot="{ close }">
                  <confirmation-modal
                    :close="close"
                    :content="$t('confirmDeletePaymentMethod')"
                    @confirm="deletePaddlePaymentMethod"
                  ></confirmation-modal>
                </template>
                <template v-slot:trigger="{ open }">
                  <button
                    @click.prevent="open"
                    class="btn btn-outline-danger shadow-2"
                  >{{ $t('paymentMethodTab.deleteButton') }}</button>
                </template>
              </modal-wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card form-card checkout">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h5>{{ $t('billingInfo') }}</h5>
      <button class="btn btn-sm btn-light" @click="backToSenderNameForm" v-if="showEditSenderNameButton">
        <i class="feather icon-edit-2 mx-1"></i>{{$t('edit')}}
      </button>
    </div>
    <div class="sender_name collapse show">
      <div class="card-block task-details ">
        <DynamicForm
          :schema="formSchema"
          :button-data="submitButton"
          :is-disabled="isDisabled"
          :initial-field-value="formData"
          form-class="justify-content-start"
          :onChangeHandler="changeHandler"
          :needPrevalidation="false"
          @submit="submit"
          v-if="!isLoading"
        >
        </DynamicForm>
        <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
      </div>
    </div>
  </div>

</template>

<script>
import { defineAsyncComponent, onMounted, ref, computed } from 'vue'
import DynamicForm from '../../forms/DynamicForm.vue'
import { useBillingInfoFormCreate } from '@/application/composables/formBillingInfoCreate.js'
import { usePaymentMethodForm } from '@/application/composables/formPaymentMethod.js'
import { useModal } from '@/application/composables/modal'
import { useI18n } from 'vue-i18n'
import { getLocalizedServerError } from '@/application/utils/localization'
import { initPaddle } from '@/application/utils/paddle'

export default {
  name: 'BillingInfoForm',
  components: {
    DynamicForm,
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  props: {
    license: {
      type: Object,
    },
  },
  setup(props) {
    const i18n = useI18n()
    const formData = ref({})
    const isDisabled = ref(false)
    const isLoading = ref(true)
    const { openModal } = useModal()
    const paddleIsLoading = ref(false)
    const paddleIsOnWork = ref(false)
    const {
      formSchema,
      submitButton,
      submit,
      getBillingInfo,
      errorMessage,
      storedDataToFormData,
      getFormSchema,
      changeHandler,
    } = useBillingInfoFormCreate()

    const {
      isPaymentDataLoading,
      getPaymentMethodData,
      deletePaymentMethod,
      paymentMethodExists,
      paymentData,
    } = usePaymentMethodForm()

    onMounted(async() => {
      const data = await getBillingInfo()
      formData.value = storedDataToFormData(data)
      formSchema.value = getFormSchema(formSchema.value, data.countries)
      isLoading.value = false

      // Load payment method
      await getPaymentMethodData()

      initPaddle(async(data) => {
        await getPaymentMethodData()
        if (data.event === 'Checkout.Complete') {
          paddleIsOnWork.value = false
        }
      })
    })

    const isPaymentFormUI = computed(
      () => !paddleIsLoading.value && !isPaymentDataLoading.value && !paddleIsOnWork.value
    )

    return {
      isPaymentFormUI,
      formSchema,
      submitButton,
      submit: async(data) => {
        isDisabled.value = true
        try {
          await submit(data)
          openModal(i18n.t('success'), i18n.t('forms.billing.success'))
        } catch (exception) {
          openModal(i18n.t('error'), getLocalizedServerError(i18n, 'forms.billing.errors.', exception?.response))
        }
        isDisabled.value = false
      },
      addPaddlePaymentMethod: async() => {
        paddleIsLoading.value = true
        paddleIsOnWork.value = true

        const k = setTimeout(() => {
          paddleIsLoading.value = false
        }, 10000)

        window.Paddle.Checkout.open({
          method: 'inline',
          override: paymentData.value.pay_url,
          disableLogout: true,
          allowQuantity: false,
          frameTarget: 'checkout-container',
          frameInitialHeight: 416,
          frameStyle: 'width:100%;max-width: 600px; min-width:312px; background-color: transparent; border: none;',
          loadCallback: function loadCallback() {
            isDisabled.value = false
            clearTimeout(k)
            paddleIsLoading.value = false
          },
        })
      },
      updatePaddlePaymentMethod: async() => {
        paddleIsLoading.value = true
        paddleIsOnWork.value = true

        const k = setTimeout(() => {
          paddleIsLoading.value = false
        }, 10000)

        window.Paddle.Checkout.open({
          override: paymentData.value.payment.update_url,
          method: 'inline',
          frameTarget: 'checkout-container',
          frameInitialHeight: 416,
          frameStyle: 'width:100%;max-width: 600px; min-width:312px; background-color: transparent; border: none;',
          loadCallback: function loadCallback() {
            isDisabled.value = false
            clearTimeout(k)
            paddleIsLoading.value = false
          },
        })
      },
      deletePaddlePaymentMethod: async() => {
        await deletePaymentMethod(paymentData.value.payment.id)
        setTimeout(() => {
          getPaymentMethodData()
        }, 2000)
      },
      cancelPaddleForm: () => {
        paddleIsOnWork.value = false
      },
      paddleIsOnWork,
      isDisabled,
      errorMessage,
      isLoading,
      isPaymentDataLoading,
      changeHandler,
      formData,
      paddleIsLoading,
      paymentData,
      paymentMethodExists,
    }
  },
}
</script>
