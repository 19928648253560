import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { getBillingInfo, saveBillingInfo } from '@/application/services/billing'
import { getCountriesList } from '@/application/utils/countries'

export const useBillingInfoFormCreate = () => {
  const i18n = useI18n()
  const formData = ref({})

  const formSchema = computed(() => ({
    company: {
      label: i18n.t('forms.billing.company'),
      name: 'company',
      type: 'text',
      as: 'input',
      rules: '',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
    },
    name: {
      label: i18n.t('forms.checkout.name'),
      name: 'name',
      type: 'text',
      as: 'input',
      rules: 'required',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
    },
    address: {
      label: i18n.t('forms.billing.address'),
      name: 'address',
      type: 'text',
      as: 'input',
      rules: 'required',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
    },
    city: {
      label: i18n.t('forms.billing.city'),
      name: 'city',
      type: 'text',
      as: 'input',
      rules: 'required',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
    },
    state: {
      label: i18n.t('forms.billing.state'),
      name: 'state',
      type: 'text',
      as: 'input',
      rules: '',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
    },
    post_code: {
      label: i18n.t('forms.billing.postalCode'),
      name: 'postalCode',
      type: 'text',
      as: 'input',
      rules: 'required',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.billing.postalCodeDescription'),
    },
    country: {
      label: i18n.t('forms.checkout.country'),
      name: 'country',
      type: 'text',
      as: 'select',
      rules: 'required',
      cols: 'col-12 col-sm-6',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      children: getCountriesList().map(country => ({
        tag: 'option',
        value: country.code,
        text: country.name,
      })),
    },
    tax_number: {
      label: i18n.t('forms.billing.tax_number'),
      name: 'tax_number',
      type: 'text',
      as: 'input',
      rules: 'max:16',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.billing.tax_numberDescription'),
    },
    phoneNumber: {
      label: i18n.t('phoneNumber'),
      name: 'phoneNumber',
      type: 'text',
      as: 'input',
      rules: '',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
    },
    email: {
      label: i18n.t('forms.billing.accountant_email'),
      name: 'email',
      type: 'text',
      as: 'input',
      rules: 'email|disposable_email',
      cols: 'col-12',
      inputClass: 'form-control',
      fieldClass: 'form-group',
      value: '',
      validateOnInput: false,
      description: i18n.t('forms.billing.accountant_emailDescription'),
    },
  }))

  const submitButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('save'),
  }))

  const changeHandler = (name, values, errors) => {
    formData.value = values
  }

  const storedDataToFormData = (storedData) => {
    return {
      company: storedData.company,
      name: storedData.name,
      address: storedData.address,
      country: storedData.country_code,
      city: storedData.city,
      state: storedData.state,
      phoneNumber: storedData.phone,
      postalCode: storedData.post_code,
      tax_number: storedData.tax_number,
      email: storedData.accountant_email,
    }
  }

  const formDataToStoreData = (formData) => ({
    company: formData.company,
    name: formData.name,
    address: formData.address,
    country_code: formData.country,
    city: formData.city,
    state: formData.state,
    phone: formData.phoneNumber,
    post_code: formData.postalCode,
    tax_number: formData.tax_number,
    accountant_email: formData.email,
  })

  const getFormSchema = (formSchema, countries) => {
    formSchema.country.children = countries.map(country => ({
      tag: 'option',
      text: country.name,
      value: country.code,
    }))
    return formSchema
  }

  return {
    formSchema,
    i18n,
    submitButton,
    changeHandler,
    storedDataToFormData,
    formDataToStoreData,
    getFormSchema,
    getBillingInfo: () => getBillingInfo().then((response) => response.data),
    submit: () => saveBillingInfo(formDataToStoreData(formData.value)).then((response) => response.data),
  }
}
