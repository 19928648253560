import { ref } from 'vue'
import { getPaymentMethodInfo, deletePaymentMethodApi } from '@/application/services/billing'

export const usePaymentMethodForm = () => {
  const isPaymentDataLoading = ref(true)
  const paymentMethodExists = ref(false)
  const paymentData = ref({})

  const getPaymentMethodData = () => {
    isPaymentDataLoading.value = true
    getPaymentMethodInfo().then((response) => {
      isPaymentDataLoading.value = false
      const data = response.data
      paymentMethodExists.value = !!data.payment
      paymentData.value = data
    })
  }

  const deletePaymentMethod = (id) => {
    deletePaymentMethodApi(id)
  }

  return {
    getPaymentMethodData,
    deletePaymentMethod,
    isPaymentDataLoading,
    paymentMethodExists,
    paymentData,
  }
}
